.header {
    background-color: #FFF;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    //box-shadow: -4px 13px 30px 0px #00000026;
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.23);
    z-index: 2;
    //height: 200px;
}

.about-us-icon-wraper {
    position: absolute;
    width: 200%;
    top: 40px;
    left: -80%;
    fill: #2F2F2F;
    filter: drop-shadow(4px 18px 36px rgba(0, 0, 0, 0.26)) drop-shadow(-13px -11px 19px rgba(104, 104, 104, 0.28)) blur(1px);
    z-index: 0;
}

.logo {
    //width: 20vw;
    width: 14vw;
    min-width: 135px;
    max-width: 275px;
    padding-top: 1vw;
    padding-bottom: 1vw;
    margin-left: 4vw;
    //position: fixed;
}


.nav-items {
    padding-top: 1vw;
    padding-bottom: 1vw;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    margin-right: 2vw;
    //padding: 0;
    padding-left: 10px;
    font-family: "Regular", Georgia;

    /*a {
        color: rgb(255, 255, 255);
    }*/
    a {
        position: relative;
        color: #282828;
        text-decoration: none;
    }

    a:hover {
        color: #EF4223;
    }

    a::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #EF4223;
        transform: scaleX(0);
        transition: transform 0.6s ease;
    }

    a:hover::before {
        transform: scaleX(1);
    }

    li {
        padding-right: 20px;
        font-weight: bold;
        font-size: calc(9px + 0.790625vw);
        overflow: hidden;
    }
}


.nav {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #FAFAFA;
    //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    top: -100%;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 0;
}

.nav-wrapper {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    padding-top: 70%;
    margin: auto;
}

.nav-items-wrapper {
    margin: 0;
    padding-left: 0px;
    font-family: "Regular", Georgia;

    a {
        position: relative;
        color: #1D1D1D;
        text-decoration: none;
    }

    a:hover {
        color: #EF4223;
    }

    a::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #EF4223;
        transform: scaleX(0);
        transition: transform 0.6s ease;
    }

    a:hover::before {
        transform: scaleX(1);
    }

    li {
        font-weight: bold;
        overflow: hidden;
        padding-bottom: 20px;
        font-family: "Regular", Georgia;
        font-size: 26px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: center;
    }
}

.top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    height: 50px;
    padding: 1em;
}

.menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

#menu-toggle {
    display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
    display: block;
    background-color: #282828;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
}

.menu-button::before {
    content: '';
    margin-top: -8px;
}

.menu-button::after {
    content: '';
    margin-top: 8px;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
}

#menu-toggle:checked+.menu-button-container .menu-button {
    background: rgba(226, 40, 40, 0);
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
}

#menu-toggle:checked~.nav {
    top: 0;
}

@media (max-width: 700px) {
    .a-logo {
        z-index: 1;
    }

    .menu-button-container {
        display: flex;
        padding-top: 10px;
    }

    .nav-items {
        position: absolute;
        top: 0;
        margin-top: 50px;
        left: 0;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    #menu-toggle~.nav-items li {
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }


    #menu-toggle:checked~.nav {
        top: 0;
    }

}