.contact-us {
    width: 100%;
    min-height: 60vh;
    padding-bottom: 10px;
    background-color: #EF4223;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 90px;
    padding-bottom: 20px;
    /*background: linear-gradient(0deg, #000000, #000000),
        linear-gradient(0deg, #282828, #282828);*/

}

.contact-message {
    color: #FFF;
    text-align: center;
    font-family: "Regular", Georgia;
    font-size: calc(18px + 0.790625vw);
    font-style: normal;
    font-weight: 400;
    //padding-top: 95px;
    max-width: 64%;
    min-width: 300px;
}


.contact-action {
    color: #FFF;
    font-family: "UltraLight", Georgia;
    font-size: calc(13px + 2.790625vw);
    font-style: italic;
    //font-weight: 275;
    line-height: normal;
    //padding-top: 30px;
}

.contact-button {
    width: 251px;
    height: 65px;
    flex-shrink: 0;
    //border-radius: 32.5px;
    border: 3px solid #FFF;
    background: #EF4223;
    color: #FFF;
    font-family: "Regular", Georgia;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 25px;
    margin-bottom: 40px;
}

.contact-button:hover {
    width: 251px;
    height: 65px;
    flex-shrink: 0;
    //border-radius: 32.5px;
    border: 3px solid #FFF;
    background: #FFF;
    color: #EF4223;
    font-family: "Regular", Georgia;
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: 25px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
    .contact-us {
        width: 100%;
        height: auto;
        min-height: 100vh;
        background-color: #EF4223;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-button {
        width: 44vw;
        height: 38px;
        flex-shrink: 0;
        //border-radius: 13.466px;
        border: 2.394px solid #FFF;
        background: #EF4223;
        color: #FFF;
        font-family: "Regular", Georgia;
        font-size: 18.773px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .contact-button:hover {
        width: 44vw;
        height: 38px;
        flex-shrink: 0;
        //border-radius: 13.466px;
        border: 2.394px solid #FFF;
        background: #FFF;
        color: #EF4223;
        font-family: "Bold", Georgia;
        font-size: 18.773px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .contact-action {
        color: #FFF;
        font-family: "UltraLight", Georgia;
        font-size: calc(28px + 2.790625vw);
        font-style: italic;
        //font-weight: 275;
        line-height: normal;
        padding-top: 30px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .contact-message {
        color: #FFF;
        text-align: center;
        font-family: "Regular", Georgia;
        font-size: calc(15px + 0.790625vw);
        font-style: normal;
        font-weight: 400;
        //padding-top: 75px;
        max-width: 64%;
        min-width: 300px;
    }

    .contact-us {
        width: 100%;
        min-height: 60vh;
        padding-bottom: 10px;
        background-color: #EF4223;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 90px;
        //padding-bottom: 20px;
        /*background: linear-gradient(0deg, #000000, #000000),
            linear-gradient(0deg, #282828, #282828);*/

    }
}