.content {
    width: 100%;
    min-height: 100vh;
    background-color: #FFF;
    text-align: left;
    padding-bottom: 15px;
}

.about-us-icon {
    width: 1900.8631591797px;
    height: 650.085144043px;
    position: absolute;
    top: 117px;
    left: -430px;
    fill: #2F2F2F;
    filter: drop-shadow(4px 18px 36px rgba(0, 0, 0, 0.26)) drop-shadow(-13px -11px 19px rgba(104, 104, 104, 0.28)) blur(1px);
    z-index: 0;
}

.about-us {
    z-index: 1;
    padding-top: 290px;
    width: 100%;
}

.about-us-call {
    position: relative;
    //width: 100%;
    margin: auto;
    width: 50%;
}

.about-us-title {
    //color: #FFF;
    font-family: "UltraLight", Georgia;
    font-size: 46px;
    //font-style: italic;
    font-weight: 275;
    line-height: normal;
    color: #262C38;
    text-shadow: -8.573px 3.81px 3.81px rgba(0, 0, 0, 0.14);
}

.about-us-decision {
    color: #3C3C3C;
    font-family: "Regular", Georgia;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.about-us-decision-black {
    color: #EF4223;
    font-family: "Regular", Georgia;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.about-us-line {
    // margin-top: 500px;
    height: 2px;
    flex-shrink: 0;
    background: #EF4223;
    position: relative;
    z-index: 1;
    width: 80%;
    margin-left: 10%;
    margin-top: 60px;
}

.about-contact-button {
    width: 251px;
    height: 65px;
    flex-shrink: 0;
    border: 2px solid #EF4223;
    background: #EF4223;
    color: #FFF;
    font-family: "Regular", Georgia;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 50px;
}

.about-us-btn {
    display: flex;
    justify-content: center;
}

.about-us-info {
    width: 100%;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-us-info-one {
    padding-top: 40px;
    color: #3C3C3C;
    text-align: center;
    font-family: "Regular", Georgia;
    font-size: calc(15px + 0.790625vw);
    font-style: normal;
    font-weight: 700;
    line-height: 138.5%;
    width: 50%;
    max-width: 654px;
}

.about-us-info-two {
    padding-top: 50px;
    color: #3C3C3C;
    text-align: center;
    font-family: "Regular", Georgia;
    font-size: calc(12px + 0.790625vw);
    font-style: normal;
    font-weight: 500;
    width: 65%;
    line-height: 156%;
}

@media only screen and (max-width: 600px) {

    .content {
        width: 100%;
        min-height: 100vh;
        height: auto;
        padding-bottom: 10px;
        background-color: #FFF;
        text-align: left;
    }

    .about-us-icon {
        /*position: absolute;
        width: 100%;
        height: 193.2191619873047px;
        top: 64px;
        left: -10%;*/
        position: absolute;
        width: 150%;
        top: -100px;
        left: -60%;
        fill: #2F2F2F;
        filter: drop-shadow(4px 18px 36px rgba(0, 0, 0, 0.26)) drop-shadow(-13px -11px 19px rgba(104, 104, 104, 0.28)) blur(1px);
        z-index: 0;
    }

    .about-us {
        z-index: 1;
        padding-top: 29vh;
        width: 100%;
    }

    .about-us-call {
        position: relative;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-us-title {
        font-family: "Light", Georgia;
        font-size: 18px;
        font-weight: 100;
        line-height: 25.19px;
        font-style: normal;
        color: #262C38;
        text-shadow: -3.042px 3.81px 3.81px rgba(0, 0, 0, 0.14);
        padding-left: 35px;
        margin-bottom: 0px;
    }

    .about-us-decision {
        color: #262C38;
        font-family: "Regular", Georgia;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        padding-left: 35px;
    }

    .about-us-decision-black {
        color: #EF4223;
        font-family: "Regular", Georgia;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
    }

    .about-us-line {
        // margin-top: 500px;
        height: 2px;
        flex-shrink: 0;
        background: #EF4223;
        position: relative;
        z-index: 1;
        width: 60%;
        margin-left: 20%;
        margin-top: 40px;
    }

    .about-contact-button {
        width: 122px;
        height: 30px;
        flex-shrink: 0;
        //border-radius: 13.466px;
        border: 0.791px solid #EF4223;
        background: #EF4223;
        color: #FFF;
        font-family: "Regular", Georgia;
        font-size: 14.773px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 20px;
    }

    .about-us-info {
        width: 100%;
        color: white;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-us-info-one {
        padding-top: 40px;
        color: #3C3C3C;
        text-align: center;
        font-family: "Bold", Georgia;
        font-size: 20px;
        font-style: normal;
        font-weight: 250;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        width: 70%;
    }

    .about-us-info-two {
        padding-top: 30px;
        color: #3C3C3C;
        text-align: center;
        font-family: "Regular", Georgia;
        width: 75%;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .about-us-icon {
        width: 100%;
        height: 576.085144043px;
        position: absolute;
        top: 157px;
        left: -100px;
        fill: #2F2F2F;
        filter: drop-shadow(4px 18px 36px rgba(0, 0, 0, 0.26)) drop-shadow(-13px -11px 19px rgba(104, 104, 104, 0.28)) blur(1px);
        z-index: 0;
    }

    .about-us {
        z-index: 1;
        padding-top: 200px;
        width: 100%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .about-us-icon {
        width: 1000px;
        height: 556px;
        position: absolute;
        top: 77px;
        left: -100px;
        fill: #2F2F2F;
        filter: drop-shadow(4px 18px 36px rgba(0, 0, 0, 0.26)) drop-shadow(-13px -11px 19px rgba(104, 104, 104, 0.28)) blur(1px);
        z-index: 0;
    }

    .about-us-info-two {
        padding-top: 20px;
        color: #3C3C3C;
        text-align: center;
        font-family: "Regular", Georgia;
        font-size: calc(12px + 0.790625vw);
        font-style: normal;
        font-weight: 500;
        width: 65%;
    }

    .about-us {
        z-index: 1;
        padding-top: 190px;
        width: 100%;
    }
}

@media only screen and (min-width: 1600px) {
    .about-us {
        padding-top: 200px;
    }
}