.our-mission {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 20px;
    background-color: #FAFAFA;
    font-size: 30px;
}

.section-title {
    font-family: "Regular", Georgia;
    //font-size: 46px;
    font-size: calc(38px + 0.790625vw);
    //font-weight: 275;
    line-height: 53px;
    letter-spacing: 0em;
    font-style: italic;
    text-align: left;
    color: #EF4223;
    margin-top: 0;
    padding-top: 115px;

}

.om-text {
    font-family: "Regular", Georgia;
    font-size: calc(15px + 0.790625vw);
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    width: 60%;
    //height: 72px;
    color: #3C3C3C;
    display: inline-flex;
    padding-top: 20px;
}

.svg-one {
    height: 12vw;
    min-width: 45px;
    min-height: 55px;
    max-height: 300px;
}

.svg-box {
    background: #ffffff;
    box-shadow: -4px 8px 15px 0px #CDCDCD;
    height: 500px;
    width: 380px;
    padding-top: 35px;
}

.div-title {
    display: flex;
    justify-content: center;
    padding-bottom: 5vh;
}

.col-sm-4 {
    padding-bottom: 5vh;
}

.mission-message {
    color: #3C3C3C;
    text-align: center;
    font-family: "Regular", Georgia;
    font-size: calc(18px + 0.790625vw);
    font-style: normal;
    font-weight: 500;
    //padding-top: 20px;
    width: 70%;
    min-width: 300px;
    margin-right: auto;
    margin-left: auto;
}

.line-white {
    width: 80%;
    height: 2px;
    flex-shrink: 0;
    background: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 60px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: inline-flex;
    flex-wrap: nowrap;
    margin-right: -15px;
    margin-left: -15px;
}

@media only screen and (max-width: 600px) {

    .our-mission {
        width: 100%;
        height: auto;
        min-height: 100vh;
        background-color: #FAFAFA;
        font-size: 30px;
    }

    .section-title {
        font-family: "Regular", Georgia;
        //font-size: 46px;
        font-size: calc(28px + 0.790625vw);
        font-weight: 275;
        line-height: 53px;
        letter-spacing: 0em;
        text-align: left;
        color: #EF4223;
        margin-top: 0;
        padding-top: 45px;
        font-style: normal;
    }

    .col-sm-4 {
        padding-bottom: 1vh;
        display: flex;
        justify-content: center;
    }

    .div-title {
        display: flex;
        justify-content: center;
        padding-bottom: 0px;
    }

    .mission-message {
        color: #3C3C3C;
        text-align: center;
        font-family: "Regular", Georgia;
        //font-size: calc(14px + 0.790625vw);
        font-size: calc(10px + 0.790625vh);
        font-style: normal;
        font-weight: 400;
        width: 85%;
        min-width: 300px;
        margin-right: auto;
        margin-left: auto;
        //padding-bottom: 10px;
        padding-top: 2vh;
    }

    .om-text {
        font-family: "Regular", Georgia;
        //font-size: calc(15px + 0.790625vw);
        font-size: calc(12px + 0.790625vh);
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        width: 85%;
        //height: 72px;
        color: #131313;
        display: inline-flex;
        padding-top: 2vh;
        // padding-top: 20px;
    }

    .line-white {
        display: none;
    }

    .row {
        display: inline-flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .svg-box {
        background: #ffffff;
        box-shadow: -4px 8px 15px 0px #CDCDCD;
        height: 20vh;
        width: 75%;
        padding-top: 2vh;
        padding-bottom: 10px;
    }

}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .section-title {
        font-family: "Regular", Georgia;
        //font-size: 46px;
        font-size: calc(38px + 0.790625vw);
        font-weight: 275;
        line-height: 53px;
        letter-spacing: 0em;
        text-align: left;
        color: #EF4223;
        margin-top: 0;
        padding-top: 95px;
        font-style: italic;
    }

    .mission-message {
        color: #3C3C3C;
        text-align: center;
        font-family: "Regular", Georgia;
        font-size: calc(14px + 0.790625vw);
        font-style: normal;
        font-weight: 500;
        padding-top: 20px;
        width: 85%;
        min-width: 300px;
        margin-right: auto;
        margin-left: auto;
    }

    .svg-box {
        background: #FFF;
        box-shadow: -4px 8px 15px 0px #CDCDCD;
        height: 360px;
        max-width: 260px;
        padding-top: 35px;
        width: auto;
    }

    .row {
        display: inline-flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .mission-message {
        color: #3C3C3C;
        text-align: center;
        font-family: "Regular", Georgia;
        font-size: calc(14px + 0.790625vw);
        font-style: normal;
        font-weight: 500;
        padding-top: 30px;
        width: 70%;
        min-width: 300px;
        margin-right: auto;
        margin-left: auto;
        line-height: 33px;
    }

    .section-title {
        font-family: "Regular", Georgia;
        //font-size: 46px;
        font-size: calc(38px + 0.790625vw);
        font-weight: 275;
        line-height: 53px;
        letter-spacing: 0em;
        text-align: left;
        color: #EF4223;
        margin-top: 0;
        padding-top: 95px;

    }

    .om-text {
        font-family: "Regular", Georgia;
        font-size: calc(12px + 0.790625vw);
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: center;
        width: 60%;
        //height: 72px;
        color: #383131;
        display: inline-flex;
    }

    .line-white {
        width: 80%;
        height: 2px;
        flex-shrink: 0;
        background: #383131;
        margin-top: 0px;
        padding-left: 10%;
        margin-bottom: 30px;
        margin-right: auto;
        margin-left: auto;
    }

    .svg-one {
        height: 10vw;
        min-width: 60px;
        min-height: 70px;
        max-height: 300px;
        color: #d61919;
    }

    .col-sm-4 {
        padding-bottom: 4vh;
    }

    .svg-box {
        background: #FFF;
        box-shadow: -4px 8px 15px 0px #CDCDCD;
        height: 370px;
        width: 300px;
        padding-top: 35px;
    }
}

@media only screen and (min-width: 1600px) {
    .container {
        display: inline;
    }
}