.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Maintenance-logo {
  height: 100vmin;
  pointer-events: none;
}

@font-face {
  font-family: "Regular";
  src: local("GTWalsheimProRegular"),
    url("./fonts/GTWalsheimProRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Light";
  src: local("GTWalsheimProLight"),
    url("./fonts/GTWalsheimProLight.ttf") format("truetype");
}

@font-face {
  font-family: "UltraLight";
  src: local("GTWalsheimProUltraLight"),
    url("./fonts/GTWalsheimProUltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src: local("GTWalsheimProBold"),
    url("./fonts/GTWalsheimProBold.ttf") format("truetype");
}

@font-face {
  font-family: "GTRegular";
  src: local("GTWalsheimRegular"),
    url("./fonts/GTWalsheimRegular.ttf") format("truetype");
}