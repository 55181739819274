.footer {
    width: 100%;
    background: #131313;
    display: flex;
}

.footer-info {
    width: 70%;
    text-align: left;
    padding-top: 60px;
    padding-left: 130px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.footer-info-logo {
    width: 183px;
    flex-shrink: 0;
}

.footer-info-text {
    color: #FFF;
    font-family: "UltraLight", Georgia;
    font-size: 18.533px;
    font-style: italic;
    font-weight: 275;
    line-height: normal;
    padding-top: 30px;
    //padding-bottom: 30px;
}

.footer-info-line {
    width: 100px;
    height: 2px;
    flex-shrink: 0;
    background: #EF4223;
}

.footer-info-line-c {
    width: 100px;
    height: 2px;
    flex-shrink: 0;
    background: #EF4223;
}

.footer-info-rights {
    color: #FFF;
    font-family: "Regular", Georgia;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.5%;
    padding-bottom: 30px;
    opacity: 0.8;
    /* 38.78px */
}

.footer-contact {
    color: #FFF;
    font-family: "Regular", Georgia;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 138.5%;
    padding-top: 60px;
    text-align: left;
    /* 47.09px */
}

.footer-contact-items {
    padding-top: 30px;

    li {
        color: #FFF;
        font-family: "Regular", Georgia;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 138.5%;
        opacity: 0.8;
        padding-bottom: 5px;
        /* 38.78px */
    }


    ul {
        list-style-type: none;
        padding-left: 0px;
    }

    a {
        color: #ffffff;
        text-decoration: none;
    }

    a:hover {
        color: #ffffff;
        opacity: 0.5;
    }
}

.svg-icon {
    margin-right: 32px;
    color: #ffffff;
}

.inline-text {
    font-family: "GTRegular", Georgia;
}

.inline {
    display: inline-flex;
}

@media only screen and (max-width: 600px) {
    .footer {
        //height: 449px;
        height: auto;
        width: 100%;
        background: #131313;
        display: flex;
        flex-flow: column;
    }

    .footer-contact {
        order: 1;
        width: 100%;
        text-align: center;
        padding-top: 40px;
        //padding-left: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }



    .footer-contact-items {
        padding-top: 30px;

        li {
            color: #FFF;
            font-family: "Regular", Georgia;
            font-size: calc(16px + 0.790625vw);
            font-style: normal;
            font-weight: 400;
            line-height: 138.5%;
            opacity: 0.8;
            padding-bottom: 15px;
            /* 38.78px */
        }


        ul {
            list-style-type: none;
            padding-left: 0px;
        }

        a {
            color: #ffffff;
            text-decoration: none;
        }


    }

    .svg-icon {
        margin-right: 20px;
        //height: 20px;
    }

    .footer-info {
        order: 2;
        width: 100%;
        text-align: center;
        padding-top: 30px;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-info-logo {
        width: 45vw;
        height: 37.763px;
    }

    .footer-info-text {
        color: #FFF;
        text-align: center;
        font-family: "UltraLight", Georgia;
        font-size: calc(16px + 0.790625vw);
        font-style: italic;
        font-weight: 275;
        line-height: normal;
    }

    .footer-info-line {
        height: 0px;
        flex-shrink: 0;
        background: #EF4223;
    }


    .footer-info-rights {
        color: #FFF;
        font-family: "Regular", Georgia;
        font-size: calc(13px + 0.790625vw);
        font-style: normal;
        font-weight: 400;
        line-height: 138.5%;
        padding-top: 30px;
        padding-bottom: 15px;
        margin-bottom: 0px;
        /* 18.965px */
    }

    .inline-text {
        padding-top: 3px;
        font-family: "GTRegular", Georgia;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1600px) {

    .footer-contact {
        color: #FFF;
        font-family: "GTRegular", Georgia;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 138.5%;
        padding-top: 20px;
        text-align: left;
        /* 47.09px */
    }

    .footer-info {
        width: 70%;
        text-align: left;
        padding-top: 20px;
        padding-left: 130px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .footer-contact-items {
        padding-top: 15px;
    }
}